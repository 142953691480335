import { ChangeEvent, ComponentProps, useCallback, useEffect, useMemo, useState } from "react";

import {
  Badge,
  Box,
  Flex,
  Heading,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";

import { mergeDeep } from "@apollo/client/utilities";
import { stringify as cacheControl } from "cache-control-parser";
import { debounce } from "debounce";
import { GetServerSideProps } from "next";
import Link from "next/link";

import {
  Card,
  Footer,
  HeaderLayout,
  Page,
  PageLimit,
  ProductPrices,
  getHeaderProps,
  useBackRoute,
} from "../components";
import { apolloServerClient } from "../graphql";
import { ProductsQuery } from "../graphql/generated/apolloHooks";
import {
  Manufacturer,
  ManufacturersDocument,
  PatientAgeGroup,
  ProductsDocument,
} from "../graphql/generated/typedDocumentNodes";
import { useAnalytics } from "../lib/analytics";
import { useIsMobile } from "../lib/media-query";
import { useQueryParams } from "../lib/query-string";
import { productUrlSlug } from "../models/product";
import { ageGroupForTranslation, translateAgeGroup } from "../models/product/ageGroups";
import { ROUTES } from "../models/router";

const compareAgeGroups = (ageGroupA: PatientAgeGroup, ageGroupB: PatientAgeGroup) => {
  const ageGroupValues: { [key in PatientAgeGroup]: number } = {
    CHILD: 0,
    TEEN: 1,
    ADULT: 2,
    ELDER: 3,
  };

  return ageGroupValues[ageGroupA] - ageGroupValues[ageGroupB];
};

const ProductCard = ({
  product,
  chainMaxInstallments,
}: {
  chainMaxInstallments: number;
  product: NonNullable<ProductsQuery["chain"]>["products"][number];
}) => {
  const [query] = useQueryParams();
  const [_, setBackRoute] = useBackRoute();
  const onClick = useCallback(
    () => setBackRoute({ pathname: ROUTES.INDEX, query }),
    [query, setBackRoute]
  );

  const linkTo = useMemo(
    () => ROUTES.DETAILS(product.id, productUrlSlug({ name: product.name })),
    [product.id, product.name]
  );

  return (
    <Link href={linkTo} passHref>
      <Card
        as="a"
        cursor="pointer"
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        alignItems="flex-start"
        justifyContent="space-between"
        onClick={onClick}
      >
        <Box minWidth={0} width="full" paddingRight={2}>
          <Heading variant="colored" isTruncated>
            {product.name}
          </Heading>
          <Text color="text.900" marginTop={4} marginBottom={{ base: 4, md: 0 }}>
            {product.shortDescription}
          </Text>
        </Box>

        <Flex direction="column" alignItems="flex-end">
          <ProductPrices
            product={product}
            chainMaxInstallments={chainMaxInstallments}
            showPackageBadge
          />
          {!product.firstAvailableDate && (
            <Badge py={1} px={2.5} mt={4} color="text.900">
              Indisponível
            </Badge>
          )}
        </Flex>
      </Card>
    </Link>
  );
};

const ProductCardGroup = ({
  title,
  productItems: products,
  chainMaxInstallments,
}: {
  title: string;
  productItems: NonNullable<ProductsQuery["chain"]>["products"];
  chainMaxInstallments: number;
}) => {
  const sortedProducts = useMemo(() => {
    type Product = typeof products[number];
    const productToNumber = (product: Product) => (product.firstAvailableDate ? 0 : 1);
    const compareProducts = (productA: Product, productB: Product) =>
      productToNumber(productA) - productToNumber(productB);

    return products.sort(compareProducts);
  }, [products]);

  return (
    <VStack spacing={5} align="flex-start" width="full">
      <Text color="text.700" fontSize="xl" as="h3" variant="roboto">
        {title}
      </Text>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={3.5} width="full">
        {sortedProducts.map(product => (
          <ProductCard
            product={product}
            chainMaxInstallments={chainMaxInstallments}
            key={product.id}
          />
        ))}
      </SimpleGrid>
    </VStack>
  );
};

const ProductSearch = ({
  textSearchValue,
  onChangeTextSearch,
  category,
  onChangeCategory,
  manufacturer,
  onChangeManufacturer,
  manufacturerOptions,
  containerProps = {},
}: {
  textSearchValue: string;
  category: string;
  manufacturer: string;
  onChangeTextSearch?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChangeCategory?: (event: ChangeEvent<HTMLSelectElement>) => void;
  onChangeManufacturer?: (event: ChangeEvent<HTMLSelectElement>) => void;
  manufacturerOptions: NonNullable<Manufacturer[]>;
  containerProps?: ComponentProps<typeof Flex>;
}) => (
  <Flex
    className="ShopSearch"
    direction="column"
    flexGrow={1}
    paddingBottom={5}
    {...containerProps}
  >
    <Stack
      className="ShopSearch__inputs"
      direction={{ base: "column", lg: "row" }}
      spacing={3}
      width="full"
    >
      <Input
        className="ShopSearch__input ShopSearch__input--product"
        placeholder="Busque por vacina ou doença"
        flexGrow={1}
        value={textSearchValue}
        onChange={onChangeTextSearch}
      />
      <Box minWidth={40}>
        <Select
          className="ShopSearch__input ShopSearch__input--category"
          onChange={onChangeCategory}
          focusBorderColor="primary.500"
          value={category}
        >
          <option value="" hidden>
            Categoria
          </option>
          <option value="">Todas</option>
          {Object.values(PatientAgeGroup)
            .map(translateAgeGroup)
            .sort()
            .map(ageGroup => (
              <option key={ageGroup} value={ageGroup.toLowerCase()}>
                {ageGroup}
              </option>
            ))}
        </Select>
      </Box>
      <Box minWidth={60}>
        <Select
          className="ShopSearch__input ShopSearch__input--manufacturer"
          value={manufacturer}
          onChange={onChangeManufacturer}
        >
          <option value="" disabled hidden>
            Fabricante
          </option>
          <option value="">Todos</option>
          {manufacturerOptions.map(({ name }) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </Select>
      </Box>
    </Stack>
  </Flex>
);

const IndexPage: Page<
  {
    products: NonNullable<ProductsQuery["chain"]>["products"];
    manufacturers: NonNullable<Manufacturer[]>;
  },
  typeof HeaderLayout
> = ({ products, manufacturers, layout: { chain } }) => {
  const isMobile = useIsMobile();

  const productsByAgeGroup = useMemo(() => {
    const result = {} as Record<PatientAgeGroup, typeof products>;
    products.forEach(product => {
      product.ageGroups.forEach(ageGroup =>
        result[ageGroup] ? result[ageGroup].push(product) : (result[ageGroup] = [product])
      );
    });

    return Object.entries(result).sort(([ageGroupA], [ageGroupB]) =>
      compareAgeGroups(ageGroupA as PatientAgeGroup, ageGroupB as PatientAgeGroup)
    ) as [PatientAgeGroup, typeof products][];
  }, [products]);

  const [query, setQueryParam] = useQueryParams();

  const {
    busca: search = "",
    categoria: category = "",
    fabricante: manufacturer = "",
  } = query as { [key: string]: string };

  const [searchState, setSearchState] = useState(search);

  const filteredProducts = useMemo(
    () =>
      productsByAgeGroup
        .map(
          ([ageGroup, products]) =>
            [
              ageGroup,
              products.filter(product => {
                const manufacturerFilter =
                  manufacturer === "" ||
                  product.__typename !== "VaccineProduct" ||
                  product.manufacturers.some(({ name }) => name === manufacturer);

                const lowerCasedSearch = search.toLowerCase().trim();
                const diseaseOrProductFilter =
                  product.name.toLowerCase().includes(lowerCasedSearch) ||
                  (product.__typename === "VaccineProduct" &&
                    product.immunizedDiseases &&
                    product.immunizedDiseases.some(({ name }) =>
                      name.toLowerCase().includes(lowerCasedSearch)
                    ));

                return manufacturerFilter && diseaseOrProductFilter;
              }),
            ] as [PatientAgeGroup, typeof products]
        )
        .filter(
          ([ageGroup, products]) =>
            (category === "" || ageGroup === ageGroupForTranslation(category)) &&
            products.length > 0
        ),
    [productsByAgeGroup, manufacturer, category, search]
  );

  const isSearchResultEmpty = useMemo(
    () => filteredProducts.every(([, products]) => products.length <= 0),
    [filteredProducts]
  );

  const { trackSearch } = useAnalytics();

  const debouncedSearch = useMemo(
    () => debounce((value: string) => setQueryParam("busca", value), 500),
    [setQueryParam]
  );

  const onSeachChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setSearchState(value);
      debouncedSearch(value);
    },
    [debouncedSearch, setSearchState]
  );

  useEffect(() => {
    const searchTrimmed = search.trim();
    if (searchTrimmed !== "" || category !== "" || manufacturer !== "") {
      trackSearch({
        text: searchTrimmed,
        category,
        manufacturer,
      });
    }
  }, [trackSearch, search, category, manufacturer]);

  useEffect(() => () => debouncedSearch?.clear(), [debouncedSearch]);

  useEffect(() => setSearchState(search), [setSearchState, search]);

  const searchField = useMemo(
    () => (
      <ProductSearch
        textSearchValue={searchState}
        onChangeTextSearch={onSeachChange}
        category={category}
        manufacturer={manufacturer}
        manufacturerOptions={manufacturers}
        onChangeCategory={event => setQueryParam("categoria", event.target.value)}
        onChangeManufacturer={event =>
          setQueryParam("fabricante", event.target.value as Manufacturer["name"])
        }
        containerProps={{
          paddingTop: { base: 0, md: isMobile ? 8 : 3 },
        }}
      />
    ),
    [searchState, onSeachChange, category, manufacturer, manufacturers, isMobile, setQueryParam]
  );

  return (
    <>
      {!isMobile && (
        <Box backgroundColor="background.primary" boxShadow="base">
          <PageLimit>{searchField}</PageLimit>
        </Box>
      )}

      <PageLimit>
        <Flex direction="column" flexGrow={1} paddingY={{ base: 10, md: 8 }}>
          {isMobile && searchField}

          <VStack spacing={6} align="flex-start">
            {filteredProducts.map(([ageGroup, products]) => (
              <ProductCardGroup
                key={ageGroup}
                title={translateAgeGroup(ageGroup)}
                productItems={products}
                chainMaxInstallments={chain.maxInstallments}
              />
            ))}

            {isSearchResultEmpty && (
              <Box display="flex" justifyContent="center" width="full">
                <Text fontSize="xl" color="text.500" marginY={6} textAlign="center">
                  Nenhum produto encontrado
                </Text>
              </Box>
            )}
          </VStack>

          <Footer marginTop={8} withClinicsDetails={!chain.logo.showInHomePage} />
        </Flex>
      </PageLimit>
    </>
  );
};

IndexPage.getLayout = (page, props) => HeaderLayout.getLayout!(page, props);

export const getServerSideProps: GetServerSideProps<ComponentProps<typeof IndexPage>> = async ({
  req,
  res,
}) => {
  if (!req.headers.host) return { notFound: true };

  const [getProductsResult, getManufacturersResult] = await Promise.all([
    apolloServerClient.query({
      query: ProductsDocument,
      variables: {
        handle: req.headers.host,
      },
    }),
    apolloServerClient.query({
      query: ManufacturersDocument,
      variables: { chainHandle: req.headers.host },
    }),
  ]);

  const products = getProductsResult.data.chain?.products;
  const manufacturers = getManufacturersResult.data.chain?.manufacturers;

  if (!products || !manufacturers) return { notFound: true };

  res.setHeader(
    "Cache-Control",
    cacheControl({
      public: true,
      "max-age": 60,
      "s-maxage": 60 * 5,
      "stale-while-revalidate": 60 * 60 * 24,
      "stale-if-error": 60 * 60 * 24 * 7,
    })
  );

  return mergeDeep(
    {
      props: {
        products,
        manufacturers,
        includeBanner: true,
        disableDefaultPageLimit: true,
        headerProps: { boxShadow: "none" },
      },
    },
    await getHeaderProps(req.headers.host)
  );
};

export default IndexPage;
