import { PatientAgeGroup } from "../../graphql/generated/typedDocumentNodes";

const ageGroupTranslation: { [key in PatientAgeGroup]: string } = {
  CHILD: "Criança",
  TEEN: "Adolescente",
  ADULT: "Adulto",
  ELDER: "Idoso",
};

export const translateAgeGroup = (ageGroup: PatientAgeGroup) => ageGroupTranslation[ageGroup];

export const ageGroupForTranslation = (translation: string) =>
  (
    Object.entries(ageGroupTranslation) as [
      PatientAgeGroup,
      typeof ageGroupTranslation[keyof typeof ageGroupTranslation]
    ][]
  ).find(([, t]) => t.toLowerCase() === translation.toLowerCase())?.[0];
