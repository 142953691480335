import { useCallback, useEffect, useState } from "react";

import { NextRouter, useRouter } from "next/router";
import { omit } from "remeda";

type SetQueryParam = (param: string, value?: string | string[] | null) => void;

export const useQueryParams = (): [NextRouter["query"], SetQueryParam] => {
  const router = useRouter();

  const [query, setQuery] = useState(router.query);

  const changeQueryParam = useCallback(
    (param, value) => {
      router.push(
        { query: value ? { ...query, [param]: value } : omit(query, [param]) },
        undefined,
        {
          shallow: true,
        }
      );
    },
    [router, query]
  ) as SetQueryParam;

  useEffect(() => {
    setQuery(router.query);
  }, [setQuery, router.query]);

  return [query, changeQueryParam];
};
